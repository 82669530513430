/**
 *
 * @description Benefits.tsx
 * @author yikkok <yikok.yong@gmail.com>
 * @version 1.0.0
 * @since 30 October 2021
 *
 */

import { FormattedMessage } from 'react-intl';
import benefitsData from '~/data/benefits.json';

export default function Benefits() {
  return (
    <div className='benefits -mt-12 relative z-8'>
      <div className='benefits-wrapper grid grid-cols-3 gap-8'>
        {benefitsData.map((item, index) => (
          <div className='benefits-item' key={index}>
            <img
              className='h-16 w-16 sm:h-28 sm:w-28 mx-auto mb-4'
              src={item.iconSrc}
              alt='Benefit icon'
              loading='lazy'
            />
            <h5 className='font-bold text-sm w-3/4 mx-auto pb-1'>
              <FormattedMessage id={item.name_id} defaultMessage={item.name} />
            </h5>
            <p className='text-xs sm:text-base text-gray-400'>
              <FormattedMessage id={item.description_id} defaultMessage={item.description} />
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}
