/**
 *
 * @description Skeleton.tsx
 * @author yikkok <yikok.yong@gmail.com>
 * @version 1.0.0
 * @since 30 October 2021
 *
 */

export default function Skeleton() {
  return (
    <div className='w-full px-2 animate-pulse my-4'>
      <div className='h-36 w-full bg-gray-200'></div>
      <h3 className='h-5 w-full mt-4 bg-gray-100'></h3>
      <p className='h-3 w-full mt-2 bg-gray-100'></p>
    </div>
  );
}
