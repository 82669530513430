/**
 *
 * @description ProductByGroups.tsx
 * @author yikkok <yikok.yong@gmail.com>
 * @version 1.0.0
 * @since 18 February 2022
 *
 */

import { useContext, useEffect, useState } from 'react';
import { ShoppingCartIcon } from '@heroicons/react/outline';
import Link from 'next/link';

import { ProductT } from '~/@customTypes/product.type';
import { ConfigContext, ConfigContextT } from '~/v2/context/ConfigProvider';
import { LocaleContext, LocaleContextT } from '~/v2/context/LocaleProvider';
import { fetchProductsByGroup } from '../product.service';
import { FormattedMessage } from 'react-intl';
import { ProjectConfig } from '~/config/ProjectConfig';

type Props = {
  groupId: string;
  title: string;
};
export default function ProductByGroups({ groupId, title }: Props) {
  const { locale } = useContext<LocaleContextT>(LocaleContext);
  const { currency } = useContext<ConfigContextT>(ConfigContext);

  const [products, setProducts] = useState<ProductT[]>([]);

  useEffect(() => {
    (async () => {
      const result = await fetchProductsByGroup(groupId, { locale, currency, limit: 4 });

      if (result && result.data) {
        setProducts(result.data);
      }
    })();
  }, [groupId, locale, currency]);

  return (
    <div className='my-8'>
      <p className='text-3xl font-semibold text-center my-8'>{title}</p>

      <div className='grid grid-cols-2 sm:grid-cols-4 gap-4'>
        {products.map((product) => {
          let url = `/product/${product.url_key}/${product.id}`;

          if (locale === 'en') {
            url = `/${locale}/product/${product.url_key}/${product.id}`;
          }

          return (
            <div key={product.id} className='p-2 sm:px-4 sm:py-4 mb-8 border border-gray-300 rounded-xl'>
              <div className='relative'>
                <div className='h-40 sm:h-52 w-full flex justify-center items-center my-2 sm:my-4'>
                  {(product.images.length > 0 && (
                    <a href={url}>
                      <img
                        loading='lazy'
                        src={product.images[0].medium_image_url}
                        alt={product.name}
                        className='object-cover h-40 sm:h-52'
                      />
                    </a>
                  )) || (
                    <a href={url}>
                      <img
                        loading='lazy'
                        src='/assets/images/logo.png'
                        alt={ProjectConfig.companyName}
                        className='object-cover h-40 sm:h-52'
                      />
                    </a>
                  )}
                </div>

                <p className='text-lg text-center mb-4 overflow-hidden text-ellipsis whitespace-nowrap'>
                  {product.name}
                </p>

                <p className='font-semibold text-center mb-4'>{product.formated_price}</p>

                <a href={url}>
                  <div className='absolute left-1/2 -translate-x-1/2 w-20 border border-gray-300 bg-white p-2 -bottom-14 rounded-2xl'>
                    <ShoppingCartIcon className='h-8 w-8 mx-auto' />
                  </div>
                </a>
              </div>
            </div>
          );
        })}
      </div>

      <div className='flex justify-center my-4'>
        <Link href={`/packages/${groupId}`}>
          <a className='w-48 px-4 py-2 bg-main-300 text-white text-center text-lg hover:text-white'>
            <FormattedMessage id='app.common.see_more' defaultMessage='See More' />
          </a>
        </Link>
      </div>
    </div>
  );
}
