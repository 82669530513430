/**
 *
 * @description ProductsSkeleton.tsx
 * @author yikkok <yikok.yong@gmail.com>
 * @version 1.0.0
 * @since 30 October 2021
 *
 */

import * as React from 'react';
import Carousel from 'react-multi-carousel';

import { responsive } from '~/v2/utils/carousel';
import Skeleton from '../product-card/Skeleton';

type Props = {
  count?: number | undefined;
};
export default function ProductsSkeleton({ count = 4 }: Props) {
  return (
    <Carousel
      draggable
      swipeable
      slidesToSlide={1}
      centerMode={false}
      focusOnSelect={false}
      additionalTransfrom={0}
      responsive={responsive}
    >
      {new Array(count).fill(0).map((_item, index) => (
        <Skeleton key={index} />
      ))}
    </Carousel>
  );
}
