import { AxiosResponse } from 'axios';
import { ResponseT } from '~/@customTypes/generic';
import { Backend } from '~/v2/utils/service';
import { BannerT, SliderT } from './slider.type';

export const fetchSlider = async (): Promise<ResponseT<SliderT[]> | null> => {
  try {
    const result: AxiosResponse<ResponseT<SliderT[]>> = await Backend.vInstance.get('sliders');

    if (result && result.data) {
      return new Promise((resolve) => resolve(result.data));
    }
  } catch (error) {
    console.log(error);
  }

  return new Promise((resolve) => resolve(null));
};

export const fetchSliders = async (): Promise<ResponseT<BannerT[]> | null> => {
  try {
    const result: AxiosResponse<ResponseT<BannerT[]>> = await Backend.vInstance.get('/shop/beccato/banner');

    if (result && result.data) {
      return new Promise((resolve) => resolve(result.data));
    }
  } catch (error) {
    console.log(error);
  }

  return new Promise((resolve) => resolve(null));
};
