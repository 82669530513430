/**
 *
 * @description HomePageContent.tsx
 * @author yikkok <yikok.yong@gmail.com>
 * @version 1.0.0
 * @since 18 February 2022
 *
 */

import React, { useContext } from 'react';
import ProductsSkeleton from '~/v2/components/product/ProductsSkeleton';
import { AppContext, AppContextT } from '~/v2/context/AppProvider';

import ProductByGroups from '~/v2/modules/product-groups/components/ProductByGroups';

export default function HomePageContent() {
  const { groups, loading } = useContext<AppContextT>(AppContext);

  if (loading) {
    return <ProductsSkeleton />;
  }

  return (
    <div className='divide-y-2 space-y-2'>
      {groups.map((group) => {
        return <ProductByGroups key={group.id} groupId={`${group.id}`} title={group.name} />;
      })}
    </div>
  );
}
