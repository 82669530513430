/**
 *
 * @description SkeletonWrapper.tsx
 * @author yikkok <yikok.yong@gmail.com>
 * @version 1.0.0
 * @since 14 November 2021
 *
 */

import { useEffect, useState } from 'react';
import { ChildrenT } from '~/@customTypes/generic';
import Skeleton from '../product-card/Skeleton';

type Props = ChildrenT & {
  delay?: number | undefined;
};

export default function SkeletonWrapper({ children, delay = 1000 }: Props) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
      clearTimeout(timeout);
    }, delay);
  }, []);

  if (loading) {
    return <Skeleton />;
  }

  return <>{children}</>;
}
